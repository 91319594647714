.textStyle{
    width: 60%;
    margin: auto;
}



@media only screen and (max-width: 480px){

    .textStyle{
        width: 92%;

    }

    .section-heading{
        padding: 1rem;
    }
}