.ctnStyle {
  padding: 1rem;
  width: 85%;
  border-radius: 10px;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.blog1col1Style {
  background-image: url("../../assets/blogimgs/5min-crash.png");
  background-size: cover;
  background-position: center center;

  border-radius: 10px;
  position: reletive;
  padding: 0px;
}

.blog2col2Style {
  background-image: url("../../assets/blogimgs/Project-Based-Vs-Milestone.png");
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  position: reletive;
  padding: 0px;
  margin-top: 1rem;
}

.blog3col3Style {
  background-image: url("../../assets/blogimgs/ADefGuide.png");
  background-size: cover;
  border-radius: 10px;
  background-position: center center;

  position: reletive;
  padding: 0px;
  margin-top: 1rem;
}



.blog4col1Style {
  background-image: url("../../assets/blogimgs/ManagingCrisis.png");
  background-size: cover;
  border-radius: 10px;
  background-position: center center;

  position: reletive;
  padding: 0px;
  margin-top: 1rem;
}


.blog5col2Style {
  background-image: url("../../assets/blogimgs/Blog6//main.png");
  background-size: cover;
  border-radius: 10px;
  background-position: center center;

  position: reletive;
  padding: 0px;
  margin-top: 1rem;
}

.ctnCardStyle {
  padding: 1rem;
  width: 85%;
  border-radius: 10px;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

/* card inner styling */

.custCard {
  position: relative;
  border-radius: 10px;
  height: 420px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.card-inner {
  z-index: 1;
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 0;
}

.blog-ctn .info {
  color: white;
  height: 558px !important;
  font-size: 17px;
  padding-left: 20px;
  transform: translateY(-6.5rem);
  transition: transform 0.5s;
  background-color: rgba(0, 0, 0, 0.65);
  padding-top: 20px;
  border-radius: 10px;
}

.custCard:hover .info {
  transform: translateY(-21.6rem);
}

.custCard:hover .info .lowerText {
  color: var(--yellow);
  font-size: 20px;
  font-weight: bold;
}

.upperText {
  font-size: 10px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-bottom: -0.4rem;
}

.blog-ctn .lowerText {
  padding-right: 20px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 2.9rem;
  margin-top: 1rem;
}


.rowData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.rowInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 13px;
}

.rowInner:nth-child(3) {
  margin-right: 20px;
}

.iconImg1 {
  width: 22px;
  margin-top: -3px;
}
.iconImg2 {
  width: 22px;
  margin-top: -3px;
}

.descriptCard {
  font-size: 13px;
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {
  .ctnStyle {
    width: 100%;

    margin-bottom: 0rem;
    margin-top: 0rem;
  }

  .ctnCardStyle {
    margin-top: 0rem;
  }
}
