.page-heading {
  width: 40%;
  margin: auto;
}

.page-description {
  width: 65%;
  margin: auto;
  margin-top: 2rem;
}
.pricing-ctn{


    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

@media only screen and (max-width: 480px) {


  


  





  .page-heading {
    width: 100%;
  }


}
