.playStoreStyle{
    width: 160px;
    position: relative;
    cursor: pointer;
    margin-top: 0.5rem;
}


.appStoreStyle{
    width: 160px;
    position: relative;
    cursor: pointer;
    margin-top: 1rem;
  }


@media only screen and (max-width: 480px){
    .playStoreStyle{
    display: block;
    width: 130px;
    }


    .appStoreStyle{

        width: 130px;
        display: block;
        

    }
}