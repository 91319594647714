.pricingSnipetCtn{
    
        background: rgba(255, 243, 207, 1);
        padding: 3rem 3rem 3rem 3rem;
    
}


@media only screen and (max-width: 480px){


    .pricingSnipetCtn{
        padding-left: 20px;
        padding-right: 20px;
    }






    
}