.ST4rowStyle {
  width: 100%;
  margin: auto;
  padding: 1rem 4rem 6rem 4rem;
  text-align: left;

  gap: 3rem;
}

@media only screen and (max-width: 480px) {
  .ST4rowStyle {
    padding: 1rem;
    gap: 1rem;
  }
}
