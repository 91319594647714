@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --yellow: #fdc526;
  --dark-gray: #111;
  --light-gray: rgba(0, 0, 0, 0.6);
  --black: #000000;
  --white: #fff;
  --purple: #4d53f1;
  --footer-bg: #444444;
  --light-white: rgba(255, 255, 255, 0.6);
}

.padding-left-right {
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-light .navbar-toggler {
  border: none;
  color: rgba(0, 0, 0, 0.55);
}
textarea:focus,
button:focus,
button:active,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

body {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

.banner-container {
  padding-right: 6rem;
  padding-left: 6rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.normal-container {
  padding: 2rem 0rem 2rem 0rem;
}

.feature-hero {
  background: rgba(255, 243, 207, 0.26);
  padding: 6rem 6rem 2rem 6rem;
  text-align: center;
}

.casestudy-hero {
  background: rgba(255, 243, 207, 0.26);
  padding: 6rem 6rem 6rem 6rem;
  text-align: center;
}

.feature-hero .page-heading {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.feature-hero .hero-img {
  display: block;
  width: 900px;
  margin: auto;
}

.feature-container {
  padding: 4rem;
  margin-top: 2rem;
}

.feature-container .img-col {
  text-align: center;
}

.feature-container .img-col .feature-img {
  width: 500px;
}

h1 {
  font-weight: bold;
}

h2 {
  font-weight: 669;
}

h4 {
  font-weight: 669;
  line-height: 28px;
  font-size: 21px;
}

h5 {
  font-weight: 669;
  line-height: 28px;
  font-size: 21px;
}

h1.banner-heading {
  color: var(--black);
  font-size: 52px;
  line-height: 52px;
}
h1.brand-heading {
  color: var(--yellow);
  font-size: 52px;
  line-height: 52px;
}
h3.step {
  color: var(--yellow);
  font-size: 24px;
  font-weight: bold;
}
h3.subHeading {
  font-size: 18px;
  font-weight: bold;
}

h1.entry-title {
  font-size: 30px;
  color: var(--black);
}

h2.section-heading {
  color: var(--black);
  font-size: 30px;
  line-height: 39px;
  text-align: center;
}

h1.banner-heading-small {
  width: 700px;
}

h2.banner-heading-small {
  color: var(--black);
  font-size: 38px;

  line-height: 44px;
}

h2.page-heading {
  color: var(--black);
  font-size: 38px;
  line-height: 44px;
}

h4.card-heading {
  color: var(--dark-gray);
  transition: 0.2s ease;
  cursor: default;
}
h4.card-heading:hover {
  color: var(--yellow);
  cursor: default;
}

h5.smallcard-heading {
  color: var(--dark-gray);
}

p.banner-description {
  color: var(--dark-gray);
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
}

p.page-description {
  color: var(--dark-gray);
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

p.card-description {
  color: var(--light-gray);
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

p.uc-heading {
  color: var(--light-gray);
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

p.section-description {
  color: var(--dark-gray);
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
}

button.cta-btn {
  border: none;
  background: var(--dark-gray);
  color: var(--white);
  padding: 0.5rem 1.4rem 0.5rem 1.4rem;
  border-radius: 4px;
  margin-top: 2rem ;
  font-weight: 400;
  transition: 0.2s ease;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 1rem;
}
a.cta-btn {
  border: none;
  background: var(--dark-gray);
  color: var(--white);
  padding: 0.55rem 1.4rem 0.5rem 1.4rem;
  border-radius: 4px;
  margin-top: 2rem;
  font-weight: 400;
  transition: 0.2s ease;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}
.feature-hero a.cta-btn{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  display: inline-block !important;
}
.cta-btn:hover {
  background: var(--yellow);
  color: var(--white);
}

.uc-btn {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: white;
  padding: 10px 22px 10px 22px;
  font-size: 14px;
  font-weight: 400;
  transition: 0.2s ease;
  text-decoration: none;
  color: var(--black);
}
.uc-btn:hover {
  background: var(--yellow);
  color: var(--white);
}

.purple-heading {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  color: var(--purple);
  text-transform: uppercase;
}

.cta-heading {
  font-weight: 500;
  color: var(--black);
  font-size: 26px;
  line-height: 37px;
}

.separator {
  border-right: 1px solid var(--light-gray);
  margin-left: 10px;
  margin-right: 16px;
}

.stickytasks-nav .navbar-nav {
  font-size: 15px;
  line-height: 23px;
  font-weight: 544;
  color: var(--dark-gray);
}

.stickytasks-nav .navbar-nav a:hover {
  background-color: #f7f6f4;
  border-radius: 4px;
}

.home-accordion .accordion-button,
.home-accordion .accordion-body {
  padding-left: 0;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: #fff3cf;
  border: none;
}
.home-accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: var(--yellow);
}

.home-accordion .card-header {
  padding-left: 0;
  background-color: transparent;
}
.home-accordion .custom-button {
  font-weight: 669;
  line-height: 28px;
  font-size: 21px;
  background-color: transparent;
  box-shadow: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  padding-left: 0;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.home-accordion .card-body {
  padding: 1rem 1.25rem;
  padding-left: 0;
  font-size: 16px;
}

.home-accordion .image-area {
  text-align: center;
}

.home-accordion .image-area img {
  width: 700px;
}

.home-accordion .image-area .image-link {
  transition: opacity 0.5s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.home-accordion .image-area .image-link.show {
  opacity: 1;
  height: auto;
}

.fbottom-links {
  list-style-type: none;
  display: inline-block;
  margin: 0;
}

.fbottom-links > li {
  color: var(--white);
  float: left;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}
.fbottom-links a {
  color: var(--white);
  text-decoration: none;
}

.fbottom-links > li:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
}

.stickytasks-nav .dropdown-menu .dropdown-header {
  padding: 0;
  font-size: 11px;
  color: var(--light-gray);
  font-weight: 544;
  text-transform: uppercase;
  padding-left: 8px;
  margin-bottom: 8px;
}
.stickytasks-nav .dropdown-menu {
  padding: 15px 10px;
}

.stickytasks-nav .dropdown-menu .nav-link {
  padding: 5px;
  padding-left: 10px;
  margin-top: 5px;
}
.stickytasks-nav .dropdown-menu .dropdown-item-container {
  display: flex;
}

.stickytasks-nav .dropdown-menu .dropdown-item-container .image-area {
  margin-right: 15px;
}
.stickytasks-nav .dropdown-menu .dropdown-item-container .image-area img {
  width: 18px;
}

.stickytasks-nav .dropdown-menu .dropdown-item-container .image-area-uc img {
  width: 30px;
}

.stickytasks-nav .dropdown-menu .dropdown-item-container .text-area h6 {
  font-weight: 544;
  font-size: 15px;
  line-height: 20px;
  color: var(--black);
  margin: 0;
  margin-top: 0px;
}

.stickytasks-nav .dropdown-menu .dropdown-item-container .text-area-uc {
  display: flex;
  align-items: center;
}

.stickytasks-nav .dropdown-menu .dropdown-item-container .text-area p {
  margin: 0;
  margin-top: 3px;
  display: block;
  color: var(--light-gray);
  font-weight: 430;
  font-size: 14px;
  line-height: 20px;
}
.singleCard {
  background-size: cover;
  border-radius: 10px;
  position: reletive;
  padding: 0px;
}

.blog-mainpage-description {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

/* Desktop */
@media only screen and (min-width: 600px) {
  .stickytasks-nav .features-dropdown .dropdown-menu {
    min-width: 50rem;
  }
  .stickytasks-nav .cases-dropdown .dropdown-menu {
    min-width: 18rem;
  }
}

/* Mobile */

@media only screen and (max-width: 480px) {

  .swiper-wrapper{
    flex-direction: column !important;
    gap: 30px !important;
  }
  .swiper-slide{
    width: 100% !important;
  }
  .CardFeatures{
    text-align: center !important;
  }
  .section-heading{
    text-align: center !important;
  }
  .section-description{
    text-align: center !important;
    width: 100% !important;
  }
  .normal-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .casestudy-hero {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mob-dis-none {
    display: none;
  }

  .card-description {
    margin-top: 5px !important;
  }

  .cta-btn {
    margin-top: 15px !important;
  }

  a.cta-btn {
    margin: auto !important;
  }

  .Cases-ctn.container-fluid {
    padding-bottom: 1rem !important;
  }

  .blog-mainpage-description {
    width: 100%;
  }

  a.cta-btn {
    display: inline-block;
    margin: auto;
  }

  h1.banner-heading {
    width: 100%;
  }

  p.page-description {
    width: 100%;
  }

  .feature-hero .page-heading {
    width: 100%;
  }

  .banner-container {
    padding: 1rem;
    display: flex;
    flex-direction: column-reverse;
  }

  .bannerImgStyle {
    width: 100% !important;
  }

  .herosection-ctn {
    width: 100%;
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .p.banner-description {
    font-size: 17px;
    width: auto;
  }

  .feature-hero {
    background: rgba(255, 243, 207, 0.26);
    padding: 2rem 1rem 2rem 1rem;
    text-align: center;
  }

  .single-ctn img {
    width: 90%;
  }

  .home-accordion .image-area img {
    width: 90%;
  }

  .feature-container {
    padding: 2rem 20px 2rem 20px;
    margin-top: 0;
  }
  .feature-left,
  .feature-right {
    margin-top: 30px;
  }
  .feature-hero .hero-img {
    width: 90%;
    margin-top: 1rem;
  }
  .feature-container .img-col .feature-img {
    color: var(--black);
    font-size: 30px;
    line-height: 39px;
    width: 90%;
  }
  h2.banner-heading-small {
    color: var(--black);
    font-size: 30px;
    line-height: 39px;
  }

  h1.banner-heading-small {
    width: 100%;
  }

  h2.section-heading {
    color: var(--black);
    font-size: 21px;
    line-height: 28px;

    margin-top: 10px;
    padding: -1px;
  }
  p.page-description {
    color: var(--dark-gray);
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
  }

  p.section-description {
    color: var(--dark-gray);
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    width: 100%;
  }

  .pricecard-ctn > .row > .col{
    flex: unset !important; ;
  }
  
  .ST4rowStyle > .col{
    
    flex: unset !important; ;
  }
}

.stayconc-ctn  .section-heading, .contact-ctn .Contcol2Style h2.section-heading{
  text-align: left;
}

.contact-ctn .Contcol1Style:nth-child(1){
  align-items: start;
  justify-content: start;
}
