.Contcol1Style  {
    display: flex;
    justify-content: center;
    align-items: center;    
    flex-direction: column;
    padding-top: 5rem;
  }



  .Contcol2Style  {
    text-align: left;
     padding-left: 5rem; 
     
     padding-top: 5rem; 

  }


  @media (max-width:480px){
      .Contcol2Style{

        padding-left: 0px;
      }
  }
