.cardStyle {
    height: 33rem;
    background-color: white;
    gap: 4rem;
    box-shadow: 0px 3px 26px #00000029;
    text-align: left;
    padding: 3rem 2rem 3rem 2rem;
  }


  @media only screen and (max-width: 480px) {
      .cardStyle{
          height: 100%;
      }
  }