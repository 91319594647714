.blog6-ul {
  padding: 10px;
}

.features-ctn-blog {
  padding: 3rem;
}

.imgStyleBlog6 {
  width: 50%;
}

.blog6-ul li {
  margin-top: 10px;
}


.feature-container-blog{
  padding: 2rem;
}

.imgStyle {
  width: 70%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.BlogImgStyle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 70%;
}

.headingStyle {
  width: 80%;
  /* text-align: left; */
  margin: auto;
}

@media (max-width: 480px) {
  .imgStyle {
    width: 100%;
  }

  .BlogImgStyle {
    width: 100%;
  }

  .headingStyle {
    width: 100%;
    padding: 0px;
  }
}
