.bannerImgStyle {
  width: 450px;
}

.descStyle {
  width: 510px;
}

.VideoBtnStyle {
  margin-left: 2rem;
  border: none;
  background: transparent;
}

@media only screen and (max-width: 480px) {





  .descStyle {
    width: 100%;
  }

  h1.banner-heading {
    font-size: 40px;
    line-height: 50px;
    color: #fdc526;
    width: 100%;
  }
  
  h1.brand-heading {
    font-size: 40px;
    line-height: 50px;
    color: #fdc526;
    width: 100%;
  }
  .btn-ctn {
    display: flex;
    margin: auto;

  }

  .VideoBtnStyle {
    display: flex;
    margin-left: .5rem;
    align-items: center;

  }
  .VideoBtnStyle img {
    width: 25px;
  }


  button.cta-btn{
    margin-top: 0px;
}
}
