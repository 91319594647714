.ctnStyle {
    margin-top: 4rem;
    margin-bottom: 8rem;
  }


  @media (max-width:480px){
      .ctnStyle{
          margin-top: 0px;
          margin-bottom: 4rem;
      }
  }