.imgStyle {
  width: 850px;
}


.single-ctn{
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.headingStyle {
  width: 100%;
  margin: auto;
 
}


.textStyle {
    width: 60%;
    margin: auto;
  }

@media only screen and (max-width: 480px) {
  .imgStyle {
    width: 100%;
  }

  .headingStyle{
      width: 100%;
     

  }


  .textStyle{
      width: 100%;
      padding: 0px;
      
  }
}
