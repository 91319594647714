.footerBottomStyle {
  display: flex;

  align-items: center;
}

.socilaUlStyle {
  list-style: none;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: flex-end;
}

.navLinkStyle {
  color: white;
  padding-right: 33px;
  font-size: 14px;
  font-weight: 300;
  border-right: 1px solid white;
}

.rowStyle {
  width: 100%;
  padding: 0px;
  gap: 0px;
}

@media only screen and (max-width: 480px) {
  .footerBottomStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .rowStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .socilaUlStyle {
    justify-content: flex-start;
  }

  .fbottom-links {
    padding: 0px;
    margin-top: 1rem;
  }
  .navLinkStyle {
    padding-left: 0px;
    border: none;
  }
}
