.ctnInside{
    padding-top: 3rem;
    width: 80%;
    margin: auto;
}


.ctnInside h2{
    margin-top: 3rem;
}


.ctnInside li{
    list-style: none;
    cursor: pointer;
    margin-top: 1.5rem;
    font-weight:400;
} 
.box:hover{

    transform: scale(1.075) rotate(.0001deg);
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 15%);
    cursor: pointer;
}


.inputStyle  {

    padding: .5rem;
    width: 20rem;

  }


@media (max-width:480px){


    .inputStyle {
        width: 80%;
    }
    .ctnInside{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }




}




