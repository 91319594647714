
.pricingCtnOutter{
  background: rgba(255, 243, 207, 1);
  padding: 2rem 2rem 0rem 2rem;
}



.pricing-section-description{

  color: var(--dark-gray);
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;

}

.pricingCtn {
  width: 90%;
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0rem 3rem 1rem 2rem;
}




.pricingCtn1Style{
    margin-top: 1rem;
    border-right: 1px dashed rgba(112, 112, 112, 1);
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }


.pricingCtn2Style{
    width: 50%;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }



  @media only screen and (max-width: 480px){
      .pricingCtn{
          display: flex;
          flex-direction: column;
          padding: 1rem;
          width: 100%;
          margin-top: 1rem;
      }


      .pricingCtn1Style{
          width: 100%;
          border: none;
          margin-top:0rem;
          padding: 1rem;
      }


      .pricingCtn2Style{

          width: 100%;
          padding: 1rem;
          margin-top: 0rem;

        }
  }