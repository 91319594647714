.ctaStyle {
  width: 70%;
  background-color: var(--yellow);

  margin: auto;
  border-radius: 5px;
  position: relative;
  top: 5rem;
  padding: 4rem;
  text-align: center;
}
.formStyle {
  text-align: center;
  margin-top: 2rem;
}
@media only screen and (max-width: 480px) {
  .ctaStyle {
    width: 100%;
    padding: 2rem;
  }
  .formStyle {
    margin-top: 1rem;
  }
}
