.headingStyle {
  text-align: center;
  width: 60%;
  margin-top: 2rem;
  
}

.colStyle1 {
  background: #fff3cf;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-top: 2rem;
  background-image: url("../../assets/imgs/blogk.png");
  background-repeat: no-repeat;

  background-position: 90% 50%;
}

.colStyle2 {
  background: #fff3cf;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-top: 2rem;
  background-image: url("../../assets//imgs/cs.png");

  background-repeat: no-repeat;
  background-position: 90% 50%;
}

.colStyle3 {
  background: #fff3cf;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-top: 2rem;
  background-image: url("../../assets/imgs/kh.png");

  background-repeat: no-repeat;
  background-position: 90% 50%;
}

@media only screen and (max-width: 480px) {
  .headingStyle {
    width: 99%;
  }

  .rowStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    

  }

  .colStyle1 {
    margin-top: 1rem;
    margin-left: 1rem;
    
  }


  .colStyle2 {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .colStyle3 {
    margin-top: 1rem;
    margin-left: 1rem;
  }
}
